import React from 'react';
import './WorkflowBox.css';

const WorkflowBox = ({
  selectedDocuments,
  setSelectedDocuments,
  availableDocuments,
  isDropdownOpen,
  setIsDropdownOpen,
}) => {
  const removeDocument = (docId) => {
    setSelectedDocuments(selectedDocuments.filter((doc) => doc.id !== docId));
  };

  const addDocument = (doc) => {
    if (!selectedDocuments.find((d) => d.id === doc.id)) {
      setSelectedDocuments([...selectedDocuments, doc]);
      setIsDropdownOpen(false);
    }
  };

  return (
    <div className="workflow-box">
      <h3>Selected Documents</h3>
      {selectedDocuments.map((doc) => (
        <div key={doc.id} className="workflow-item">
          <input type="checkbox" id={doc.id} name="document" value={doc.name} />
          <label htmlFor={doc.id}>{doc.name}</label>
          <button className="remove-btn" onClick={() => removeDocument(doc.id)}>
            ×
          </button>
        </div>
      ))}
      <button className="add-document-btn" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        Add document
      </button>
      {isDropdownOpen && (
        <div className="document-dropdown">
          {availableDocuments.map((doc) => (
            <div key={doc.id} className="document-dropdown-item" onClick={() => addDocument(doc)}>
              {doc.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WorkflowBox;
