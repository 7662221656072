import React from 'react';
import config from '../../config';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import Chart from '../Chart';
import './QueryBox.css';

// Configure marked to use fewer line breaks
marked.setOptions({
  breaks: false,
  gfm: true
});

const QueryBox = ({ query, setQuery, responses, setResponses, selectedCompany }) => {
  const renderResponse = (response) => {
    // If it's a chart response
    if (response && response.type === 'chart' && response.chartData) {
      return <Chart chartData={response.chartData} />;
    }

    // If it starts with "Query: ", render it as a user query
    if (typeof response === 'string' && response.startsWith('Query: ')) {
      return (
        <div className="user-query">
          <div className="user-query-bubble">
            {response.substring(7)}
          </div>
        </div>
      );
    }

    // For HTML responses (model outputs)
    if (typeof response === 'string' && response.startsWith('<div class="response-text">')) {
      return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(response) }} />;
    }

    // For all other responses (including loading dots)
    return <div className="message">{response}</div>;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (query.trim()) {
      setResponses((prevResponses) => [
        ...prevResponses,
        `Query: ${query}`,
        'Response: •'
      ]);
      setQuery('');

      let dotCount = 1;
      const dotInterval = setInterval(() => {
        setResponses((prevResponses) => {
          const updatedResponses = [...prevResponses];
          updatedResponses[updatedResponses.length - 1] =
            `Response: ${'•'.repeat((dotCount % 3) + 1)}`;
          return updatedResponses;
        });
        dotCount++;
      }, 350);

      try {
        const data = { question: query };
        console.log('Sending request:', data);
        
        const response = await fetch(`${config.apiUrl}/query`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });

        const result = await response.json();
        console.log('Received response:', result);
        
        clearInterval(dotInterval);

        setResponses((prevResponses) => {
          const newResponses = [...prevResponses.slice(0, -1)];
          
          let textResponse = '';
          let chartData = null;
          
          if (result.answer && result.answer.text_response) {
            textResponse = result.answer.text_response
              .replace(/\n\n+/g, '\n\n')
              .trim();
            chartData = result.answer.chart_data;
          } else if (typeof result.answer === 'string') {
            textResponse = result.answer;
          } else if (typeof result === 'string') {
            textResponse = result;
          } else {
            textResponse = 'Error: Unexpected response format';
          }

          // Add the text response with proper formatting
          const parsedResponse = marked.parse(textResponse);
          newResponses.push(`<div class="response-text">${DOMPurify.sanitize(parsedResponse)}</div>`);
          
          // Add chart if available
          if (chartData && chartData.should_chart) {
            newResponses.push({
              type: 'chart',
              chartData: chartData
            });
          }
          
          return newResponses;
        });
      } catch (error) {
        console.error('Query error:', error);
        clearInterval(dotInterval);
        setResponses((prevResponses) => [
          ...prevResponses.slice(0, -1),
          'Response: An error occurred while processing your query.'
        ]);
      }
    }
  };

  return (
    <>
      <div className="query-messages">
        {responses.map((response, index) => (
          <div key={index}>
            {renderResponse(response)}
          </div>
        ))}
      </div>
      <div className="query-input-container">
        <form onSubmit={handleSubmit} className="query-input-wrapper">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Ask a question about the document..."
            className="query-input"
          />
          <button type="submit" className="query-submit-btn">
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default QueryBox;
