import React from 'react';
import { Line, Bar, Pie, Scatter } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

// Define a color palette for different datasets
const colorPalette = [
  { borderColor: 'rgb(75, 192, 192)', backgroundColor: 'rgba(75, 192, 192, 0.5)' },
  { borderColor: 'rgb(255, 99, 132)', backgroundColor: 'rgba(255, 99, 132, 0.5)' },
  { borderColor: 'rgb(153, 102, 255)', backgroundColor: 'rgba(153, 102, 255, 0.5)' },
  { borderColor: 'rgb(255, 159, 64)', backgroundColor: 'rgba(255, 159, 64, 0.5)' },
  { borderColor: 'rgb(54, 162, 235)', backgroundColor: 'rgba(54, 162, 235, 0.5)' },
];

const Chart = ({ chartData }) => {
  if (!chartData || !chartData.should_chart) return null;

  const chartConfig = {
    labels: chartData.labels,
    datasets: chartData.datasets.map((dataset, index) => ({
      ...dataset,
      ...colorPalette[index % colorPalette.length],
      borderWidth: 2,
      tension: 0.1, // Adds slight curve to lines
    })),
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#fff', // White text for legend
          padding: 20,
        },
      },
      title: {
        display: true,
        text: chartData.title,
        color: '#fff', // White text for title
        font: {
          size: 16,
          weight: 'bold',
        },
        padding: {
          top: 10,
          bottom: 30,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)', // Subtle grid lines
        },
        ticks: {
          color: '#fff', // White text for y-axis
        },
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)', // Subtle grid lines
        },
        ticks: {
          color: '#fff', // White text for x-axis
        },
      },
    },
  };

  const ChartComponent = {
    line: Line,
    bar: Bar,
    pie: Pie,
    scatter: Scatter,
  }[chartData.chart_type];

  return (
    <div className="chart-container">
      <ChartComponent data={chartConfig} options={options} />
    </div>
  );
};

export default Chart; 