import React, { useEffect } from 'react';
import config from '../../config';
import './FactsBox.css';

const FactsBox = ({
  activeTab,
  setActiveTab,
  selectedCompany,
  factsData,
  setFactsData,
  statsData,
  setStatsData,
  notes,
  setNotes,
  activeNoteId,
  setActiveNoteId,
  sidebarCollapsed,
  setSidebarCollapsed,
  setIframeLoaded,
}) => {
  useEffect(() => {
    if (activeTab === 'Facts') {
      loadJsonData('facts', selectedCompany, setFactsData);
    } else if (activeTab === 'Stats') {
      loadJsonData('stats', selectedCompany, setStatsData);
    }
  }, [activeTab, selectedCompany]);

  const loadJsonData = (type, selectedCompany, setter) => {
    fetch(`${config.apiUrl}/static/${selectedCompany.toLowerCase()}/${type}_data.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          throw new Error(`File not found`);
        }
        setter(data);
      })
      .catch((error) => {
        console.error(`Failed to load ${type} data:`, error);
        setter(null);
      });
  };

  const renderTable = (data, headers) => {
    const getCellClass = (header, value) => {
      if (header === 'change' || header.includes('growth')) {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          return numValue > 0 ? 'positive' : numValue < 0 ? 'negative' : '';
        }
      }
      return '';
    };
  
    return (
      <table>
        <thead>
          <tr>{headers.map(header => <th key={header}>{header}</th>)}</tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {headers.map(header => (
                <td key={header} className={getCellClass(header, row[header])}>
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handleAddNote = () => {
    const newNote = {
      id: Date.now(),
      title: 'Untitled',
      content: ''
    };
    setNotes([newNote, ...notes]);
    setActiveNoteId(newNote.id);
  };

  const handleDeleteNote = (noteId) => {
    setNotes(notes.filter(note => note.id !== noteId));
    if (activeNoteId === noteId) {
      setActiveNoteId(notes.length > 1 ? notes[0].id : null);
    }
  };

  const handleNoteSelect = (noteId) => {
    setActiveNoteId(noteId);
  };

  const handleNoteChange = (field, value) => {
    setNotes(
      notes.map(note =>
        note.id === activeNoteId ? { ...note, [field]: value } : note
      )
    );
  };

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const activeNote = notes.find(note => note.id === activeNoteId);

  return (
    <div className="facts-container">
      <div className="tab-navigation">
        <button 
          onClick={() => setActiveTab('Document')} 
          className={activeTab === 'Document' ? 'active' : ''}
        >
          Document
        </button>
        <button 
          onClick={() => setActiveTab('Facts')} 
          className={activeTab === 'Facts' ? 'active' : ''}
        >
          Facts
        </button>
        <button onClick={() => setActiveTab('Stats')} className={activeTab === 'Stats' ? 'active' : ''}>
          Stats
        </button>
        <button
          onClick={() => setActiveTab('Notebook')}
          className={activeTab === 'Notebook' ? 'active' : ''}
        >
          Notebook
        </button>
      </div>

      <div className="modular-content">
        {activeTab === 'Document' && (
          <div className="document-viewer-container">
            <div className="document-viewer">
              <iframe
                src={`${config.apiUrl}/static/${selectedCompany.toLowerCase()}/latest.html`}
                title="Document Viewer"
                className="document-iframe"
                sandbox="allow-same-origin allow-scripts"
                onLoad={() => setIframeLoaded(true)}
              ></iframe>
            </div>
          </div>
        )}
        {activeTab === 'Facts' && factsData && (
          <div className="modular-boxes">
            {factsData.sections.map((section, index) => (
              <div key={index} className="modular-box">
                <h3>{section.title}</h3>
                <ul className="bullet-points">
                  {section.content.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'Stats' && statsData && (
          <div className="stats-section">
            <h3>Product Sales by Category</h3>
            {renderTable(statsData.productSales, ["category", "2024", "change", "2023", "2022"])}

            <h3>Segment Operating Performance</h3>
            {renderTable(statsData.segmentPerformance, ["region", "2024", "change", "2023", "2022"])}

            <h3>Shareholder Equity and Paid-in Capital</h3>
            {renderTable(statsData.shareholderEquity, ["category", "2024", "2023", "2022"])}

            <h3>Stock Performance Comparison</h3>
            {renderTable(statsData.stockPerformance, ["year", "apple", "sp500", "dj_tech"])}
          </div>
        )}

        {activeTab === 'Notebook' && (
          <div className="notebook">
            <div className={`notes-list ${sidebarCollapsed ? 'collapsed' : ''}`}>
              <div className="notes-list-header">
                <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
                  {sidebarCollapsed ? '>' : '<'}
                </button>
                {!sidebarCollapsed && (
                  <button className="add-note-btn" onClick={handleAddNote}>+</button>
                )}
              </div>
              <div className="notes-list-content">
                {notes.map(note => (
                  <div
                    key={note.id}
                    className={`note-item ${note.id === activeNoteId ? 'active' : ''}`}
                    onClick={() => handleNoteSelect(note.id)}
                  >
                    <span className="note-title">{note.title}</span>
                    <button
                      className="delete-note-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteNote(note.id);
                      }}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="note-editor">
              {activeNote && (
                <>
                  <input
                    type="text"
                    className="note-title-input"
                    value={activeNote.title}
                    onChange={(e) => handleNoteChange('title', e.target.value)}
                    placeholder="Note title"
                  />
                  <textarea
                    className="note-content-input"
                    value={activeNote.content}
                    onChange={(e) => handleNoteChange('content', e.target.value)}
                    placeholder="Start writing..."
                  ></textarea>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FactsBox;