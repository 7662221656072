import React, { useRef } from 'react';
import config from '../../config';
import './DocumentViewerBox.css';

const DocumentViewerBox = ({ selectedCompany, searchTerm, setSearchTerm, iframeLoaded, setIframeLoaded }) => {
  const iframeRef = useRef();

  return (
    <div className="document-viewer">
      {/* Search bar can be added back if needed */}
      {/* <div className="search-bar">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
          className="search-input"
        />
        <button onClick={handleSearch} className="search-button">
          🔍
        </button>
      </div> */}
      <iframe
        ref={iframeRef}
        src={`${config.apiUrl}/static/${selectedCompany.toLowerCase()}/latest.html`}
        title="Document Viewer"
        className="document-iframe"
        sandbox="allow-same-origin allow-scripts"
        onLoad={() => setIframeLoaded(true)}
      ></iframe>
    </div>
  );
};

export default DocumentViewerBox;
