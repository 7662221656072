import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import QueryBox from './grid/QueryBox';
import config from '../config';
import './Overview.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Overview = ({ selectedCompany }) => {
  const [stockStats, setStockStats] = useState({
    previousClose: '-',
    open: '-',
    volume: '-',
    marketCap: '-',
    sharesOut: '-',
    forwardPE: '-',
    dividendYield: '-'
  });

  const [keyMetrics, setKeyMetrics] = useState({
    peRatio: '-',
    priceToSales: '-',
    priceToBook: '-',
    priceToCashFlow: '-',
    totalDebtEquity: '-',
    longTermDebtEquity: '-',
    returnOnInvestment: '-',
    returnOnEquity: '-'
  });

  const [chartData, setChartData] = useState([]);
  const [news, setNews] = useState([
    { id: 1, title: "Latest earnings report exceeds expectations" },
    { id: 2, title: "New product launch announced" },
    { id: 3, title: "Strategic partnership formed with key supplier" }
  ]);

  const [query, setQuery] = useState('');
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    const fetchStockData = async () => {
      if (!selectedCompany) return;

      try {
        // Fetch intraday data
        const response = await fetch(
          `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${selectedCompany}&interval=5min&apikey=${process.env.REACT_APP_ALPHA_VANTAGE_KEY}`
        );
        const data = await response.json();

        if (data['Time Series (5min)']) {
          const formattedData = Object.entries(data['Time Series (5min)'])
            .map(([timestamp, values]) => ({
              timestamp,
              close: parseFloat(values['4. close'])
            }))
            .reverse();

          setChartData(formattedData);
          
          // Fetch global quote for additional data
          const quoteResponse = await fetch(
            `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${selectedCompany}&apikey=${process.env.REACT_APP_ALPHA_VANTAGE_KEY}`
          );
          const quoteData = await quoteResponse.json();
          const quote = quoteData['Global Quote'];

          if (quote) {
            setStockStats({
              previousClose: quote['08. previous close'],
              open: quote['02. open'],
              volume: parseInt(quote['06. volume']).toLocaleString(),
              marketCap: '-', // Would need additional API call for this
              sharesOut: '-',
              forwardPE: '-',
              dividendYield: parseFloat(quote['09. dividend yield']).toFixed(2)
            });
          }
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };

    fetchStockData();
  }, [selectedCompany]);

  const simplifiedChartData = {
    labels: chartData.map(data => {
      const date = new Date(data.timestamp);
      return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
    }),
    datasets: [
      {
        label: 'Price',
        data: chartData.map(data => data.close),
        borderColor: '#32CD32',
        borderWidth: 1.5,
        pointRadius: 0,
        tension: 0.4,
        fill: false
      }
    ]
  };

  const simplifiedChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            return `$${context.parsed.y.toFixed(2)}`;
          }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        display: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: '#999',
          callback: function(value) {
            return '$' + value.toFixed(0);
          }
        }
      }
    }
  };

  return (
    <div className="overview-grid">
      <div className="left-container">
        <div className="stats-panel">
          <h2>Key Statistics</h2>
          <div className="mini-chart">
            <Line data={simplifiedChartData} options={simplifiedChartOptions} />
          </div>
          <div className="stats-grid">
            {Object.entries(stockStats).map(([key, value]) => (
              <div key={key} className="stat-item">
                <span>{key.replace(/([A-Z])/g, ' $1').trim()}</span>
                <span>{value}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="news-panel">
          <h2>Latest News</h2>
          <div className="news-list">
            {news.map(item => (
              <div key={item.id} className="news-item">
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="right-container">
        <div className="brief-panel">
          <h2>Company Brief</h2>
          <p>
            {selectedCompany} is a leading company listed on the stock market. View the latest statistics
            and metrics in the panels on the left.
          </p>
        </div>

        <div className="query-panel">
          <h2>AI Assistant</h2>
          <QueryBox
            query={query}
            setQuery={setQuery}
            responses={responses}
            setResponses={setResponses}
            selectedCompany={selectedCompany}
            endpoint="overview/query"
            context={{ stockStats, keyMetrics, news }}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview; 