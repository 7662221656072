import React, { useState, useEffect, useRef } from 'react';
import './DocumentViewer.css';
import config from '../config';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import DocumentViewerBox from './grid/DocumentViewerBox';
import FactsBox from './grid/FactsBox';
import WorkflowBox from './grid/WorkflowBox';
import QueryBox from './grid/QueryBox';

const DocumentViewer = ({ selectedDocument, selectedCompany }) => {
  const [query, setQuery] = useState('');
  const [responses, setResponses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [iframeLoaded, setIframeLoaded] = useState(false); //Added for search currently not connected to backend
  const iframeRef = useRef();
  const [selectedDocuments, setSelectedDocuments] = useState([
    { id: 'doc1', name: 'Form 10-K, 2024' },
    { id: 'doc2', name: 'Form 8-K' },
    { id: 'doc3', name: 'Form S-1' }
  ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Facts');
  const [factsData, setFactsData] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [notes, setNotes] = useState([]);
  const [activeNoteId, setActiveNoteId] = useState(null);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const availableDocuments = [
    { id: 'doc4', name: 'Form 10-Q' },
    { id: 'doc5', name: 'Form 8-K (Supplemental)' },
    { id: 'doc6', name: 'Form 10-K, 2023' }
  ];

  // Fetch Facts JSON data
  // Fetch Stats JSON data
  useEffect(() => {
    if (activeTab === 'Facts') {
      loadJsonData('facts', selectedCompany, setFactsData);
    } else if (activeTab === 'Stats') {
      loadJsonData('stats', selectedCompany, setStatsData);
    }
  }, [activeTab, selectedCompany]);

  const loadJsonData = (type, selectedCompany, setter) => {
    fetch(`${config.apiUrl}/static/${selectedCompany.toLowerCase()}/${type}_data.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.error) {
          throw new Error(`File not found`);
        }

        setter(data);
      })
      .catch(error => {
        console.error(`Failed to load ${type} data:`, error);
        setter(null);
      });
};

  // Load notes from localStorage or initialize
  useEffect(() => {
    const savedNotes = JSON.parse(localStorage.getItem('notes')) || [];
    setNotes(savedNotes);
    if (savedNotes.length > 0) {
      setActiveNoteId(savedNotes[0].id);
    }
  }, []);

  // Save notes to localStorage when notes change
  useEffect(() => {
    localStorage.setItem('notes', JSON.stringify(notes));
  }, [notes]);

  const renderTable = (data, headers) => {
    const getCellClass = (header, value) => {
      if (header === 'change' || header.includes('growth')) {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          return numValue > 0 ? 'positive' : numValue < 0 ? 'negative' : '';
        }
      }
      return '';
    };
  
    return (
      <table>
        <thead>
          <tr>{headers.map(header => <th key={header}>{header}</th>)}</tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {headers.map(header => (
                <td key={header} className={getCellClass(header, row[header])}>
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

// Update handleSearch function
// Currently not working as it should

const handleSearch = async () => {
  if (!searchTerm.trim()) return;

  try {
    const response = await fetch(`${config.apiUrl}/api/search`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        file_path: `${selectedCompany.toLowerCase()}/latest.html`,
        term: searchTerm,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data.matches);
    } else {
      console.error("Search failed");
    }
  } catch (error) {
    console.error("Search error:", error);
  }
};


return (
  <div className="grid-container">
    <div className="workflow-box-container">
      <FactsBox
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        selectedCompany={selectedCompany}
        factsData={factsData}
        setFactsData={setFactsData}
        statsData={statsData}
        setStatsData={setStatsData}
        notes={notes}
        setNotes={setNotes}
        activeNoteId={activeNoteId}
        setActiveNoteId={setActiveNoteId}
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        iframeLoaded={iframeLoaded}
        setIframeLoaded={setIframeLoaded}
      />
      <WorkflowBox
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        availableDocuments={availableDocuments}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
      />
    </div>
    <div className="query-box-container">
      <QueryBox
        query={query}
        setQuery={setQuery}
        responses={responses}
        setResponses={setResponses}
        selectedCompany={selectedCompany}
      />
    </div>
  </div>
);
};

export default DocumentViewer;
