import React from 'react';
import './Sidebar.css';

const Sidebar = ({ activeTool, setActiveTool }) => {
  return (
    <div className="sidebar">
      <div 
        className={`tool ${activeTool === 'overview' ? 'active' : ''}`} 
        onClick={() => setActiveTool('overview')}
      >
        <span className="icon">📊</span>
        <span className="label">Overview</span>
      </div>
      <div 
        className={`tool ${activeTool === 'upload' ? 'active' : ''}`} 
        onClick={() => setActiveTool('upload')}
      >
        <span className="icon">📄</span>
        <span className="label">Documents</span>
      </div>
      <div 
        className={`tool ${activeTool === 'stocks' ? 'active' : ''}`} 
        onClick={() => setActiveTool('stocks')}
      >
        <span className="icon">📈</span>
        <span className="label">Stocks</span>
      </div>
      <div 
        className={`tool ${activeTool === 'news' ? 'active' : ''}`} 
        onClick={() => setActiveTool('news')}
      >
        <span className="icon">📰</span>
        <span className="label">News</span>
      </div>
    </div>
  );
};

export default Sidebar;
