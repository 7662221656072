import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import './StockData.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const StockData = ({ selectedCompany }) => {
  const [stockData, setStockData] = useState([]);
  const [metrics, setMetrics] = useState({
    price: '-',
    change: '-',
    changePercent: '-',
    volume: '-',
    high: '-',
    low: '-',
  });
  const [loading, setLoading] = useState(true);
  const [timeframe, setTimeframe] = useState('5min');
  const [viewType, setViewType] = useState('intraday'); // 'intraday' or 'daily'

  useEffect(() => {
    const fetchStockData = async () => {
      if (!selectedCompany) return;
      
      setLoading(true);
      try {
        let url;
        let data;
        
        if (viewType === 'intraday') {
          url = `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${selectedCompany}&interval=${timeframe}&apikey=${process.env.REACT_APP_ALPHA_VANTAGE_KEY}`;
          console.log('Fetching from URL:', url);
          const response = await fetch(url);
          data = await response.json();
          console.log('API Response:', data);
          
          if (data['Time Series (${timeframe})']) {
            const formattedData = Object.entries(data[`Time Series (${timeframe})`])
              .map(([timestamp, values]) => ({
                timestamp,
                open: parseFloat(values['1. open']),
                high: parseFloat(values['2. high']),
                low: parseFloat(values['3. low']),
                close: parseFloat(values['4. close']),
                volume: parseInt(values['5. volume'])
              }))
              .reverse();

            console.log('Formatted Data:', formattedData);
            setStockData(formattedData);

            const latest = formattedData[formattedData.length - 1];
            const previous = formattedData[formattedData.length - 2];
            
            if (latest && previous) {
              const change = (latest.close - previous.close).toFixed(2);
              const changePercent = ((change / previous.close) * 100).toFixed(2);
              
              const newMetrics = {
                price: latest.close.toFixed(2),
                change,
                changePercent,
                volume: latest.volume.toLocaleString(),
                high: latest.high.toFixed(2),
                low: latest.low.toFixed(2),
              };
              console.log('Setting metrics:', newMetrics);
              setMetrics(newMetrics);
            }
          } else {
            console.error('No time series data in response:', data);
            if (data.Note) {
              console.warn('API Note:', data.Note);
            }
            if (data.Information) {
              console.warn('API Information:', data.Information);
            }
          }
        } else {
          // Daily data
          const outputsize = timeframe === 'full' ? 'full' : 'compact';
          url = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${selectedCompany}&outputsize=${outputsize}&apikey=${process.env.REACT_APP_ALPHA_VANTAGE_KEY}`;
          console.log('Fetching from URL:', url);
          const response = await fetch(url);
          data = await response.json();
          console.log('API Response:', data);

          if (data['Time Series (Daily)']) {
            let formattedData = Object.entries(data['Time Series (Daily)'])
              .map(([timestamp, values]) => ({
                timestamp,
                open: parseFloat(values['1. open']),
                high: parseFloat(values['2. high']),
                low: parseFloat(values['3. low']),
                close: parseFloat(values['4. close']),
                volume: parseInt(values['5. volume'])
              }))
              .reverse();

            // Filter data based on timeframe
            if (timeframe === '1month') {
              formattedData = formattedData.slice(-22);
            } else if (timeframe === '3months') {
              formattedData = formattedData.slice(-66);
            } else if (timeframe === '6months') {
              formattedData = formattedData.slice(-132);
            } else if (timeframe === '1year') {
              formattedData = formattedData.slice(-264);
            }

            console.log('Formatted Data:', formattedData);
            setStockData(formattedData);

            const latest = formattedData[formattedData.length - 1];
            const previous = formattedData[formattedData.length - 2];
            
            if (latest && previous) {
              const change = (latest.close - previous.close).toFixed(2);
              const changePercent = ((change / previous.close) * 100).toFixed(2);
              
              const newMetrics = {
                price: latest.close.toFixed(2),
                change,
                changePercent,
                volume: latest.volume.toLocaleString(),
                high: latest.high.toFixed(2),
                low: latest.low.toFixed(2),
              };
              console.log('Setting metrics:', newMetrics);
              setMetrics(newMetrics);
            }
          } else {
            console.error('No time series data in response:', data);
            if (data.Note) {
              console.warn('API Note:', data.Note);
            }
            if (data.Information) {
              console.warn('API Information:', data.Information);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
        console.error('Error details:', {
          message: error.message,
          stack: error.stack
        });
      } finally {
        setLoading(false);
      }
    };

    console.log('Selected Company:', selectedCompany);
    console.log('View Type:', viewType);
    console.log('Timeframe:', timeframe);
    console.log('API Key:', process.env.REACT_APP_ALPHA_VANTAGE_KEY);
    
    fetchStockData();
    // Set up polling every minute for intraday data only
    let interval;
    if (viewType === 'intraday') {
      interval = setInterval(fetchStockData, 60000);
    }
    return () => interval && clearInterval(interval);
  }, [selectedCompany, timeframe, viewType]);

  const intradayTimeframes = ['5min', '15min', '30min', '60min'];
  const dailyTimeframes = ['1month', '3months', '6months', '1year', 'full'];

  const formatLabel = (timestamp) => {
    const date = new Date(timestamp);
    if (viewType === 'intraday') {
      return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
    } else {
      return date.toLocaleDateString();
    }
  };

  const chartData = {
    labels: stockData.map(data => formatLabel(data.timestamp)),
    datasets: [
      {
        label: 'Stock Price',
        data: stockData.map(data => data.close),
        fill: false,
        borderColor: '#32CD32',
        borderWidth: 1.5,
        pointRadius: 0,
        tension: 0.1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: '#fff',
        bodyColor: '#fff',
        callbacks: {
          label: function(context) {
            return `$${context.parsed.y.toFixed(2)}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#999',
          maxRotation: 0
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#999',
          callback: function(value) {
            return '$' + value.toFixed(2);
          }
        }
      }
    },
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    }
  };

  return (
    <div className="stock-data-container">
      <div className="stock-header">
        <h2>{selectedCompany} Stock Data</h2>
        <div className="view-selector">
          <button
            className={`view-button ${viewType === 'intraday' ? 'active' : ''}`}
            onClick={() => {
              setViewType('intraday');
              setTimeframe('5min');
            }}
          >
            Intraday
          </button>
          <button
            className={`view-button ${viewType === 'daily' ? 'active' : ''}`}
            onClick={() => {
              setViewType('daily');
              setTimeframe('1month');
            }}
          >
            Daily
          </button>
        </div>
        <div className="timeframe-selector">
          {(viewType === 'intraday' ? intradayTimeframes : dailyTimeframes).map(option => (
            <button
              key={option}
              className={`timeframe-button ${timeframe === option ? 'active' : ''}`}
              onClick={() => setTimeframe(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      <div className="metrics-grid">
        <div className="metric-item">
          <span className="metric-label">Price</span>
          <span className="metric-value">${metrics.price}</span>
        </div>
        <div className="metric-item">
          <span className="metric-label">Change</span>
          <span className={`metric-value ${parseFloat(metrics.change) >= 0 ? 'positive' : 'negative'}`}>
            ${metrics.change} ({metrics.changePercent}%)
          </span>
        </div>
        <div className="metric-item">
          <span className="metric-label">Volume</span>
          <span className="metric-value">{metrics.volume}</span>
        </div>
        <div className="metric-item">
          <span className="metric-label">Day High</span>
          <span className="metric-value">${metrics.high}</span>
        </div>
        <div className="metric-item">
          <span className="metric-label">Day Low</span>
          <span className="metric-value">${metrics.low}</span>
        </div>
      </div>

      <div className="chart-container">
        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          <Line data={chartData} options={chartOptions} />
        )}
      </div>
    </div>
  );
};

export default StockData;
